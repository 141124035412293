import React, { Component } from "react"
import avion from "../images/icono-nosiglia-pro.svg"
import { Link } from "gatsby"
import ThemeContext from "../context/ThemeContext"
const ListLink = props => (
  <li className="link">
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default class Navigation extends Component {
  static contextType = ThemeContext
  state = {
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.navOnScroll)
  }

  componentWillUnmount() {
    window.addEventListener("scroll", this.navOnScroll)
  }

  navOnScroll = () => {
    if (window.scrollY > 20) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }
  render() {
    const { scrolled } = this.state
    //const theme = this.context

    return (
      <nav className={scrolled ? "nav scroll" : "nav"}>
        <div className="nav-container">
          <div className="top-movil">
            <div className="logo">
              <Link to={`/`}>
                <img src={avion} className="favicon" alt="Paper-plane" />
                <span className="texto">
                  <strong>Nosiglia</strong>.pro
                </span>
              </Link>
            </div>
            <ul className="links">
              <ListLink to="/en-construccion">En construcción</ListLink>
            </ul>
            <ul className="mobile-links">
              <ListLink to="/en-construccion/">En construcción</ListLink>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
