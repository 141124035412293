import React, {Component} from "react"
//import {css} from "@emotion/core"
import {Link} from "gatsby"

export default class Footer extends Component {
    render(){
        return(
            <footer>
                <div></div>
                <div>
                    <Link to={'/'}>Juan Nosiglia - Consultor Marketing Digital ©2021</Link>
                </div>
                <div></div>
            </footer>
        )
    }
}